@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?wj6ec7');
  src:  url('fonts/icomoon.eot?wj6ec7#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?wj6ec7') format('truetype'),
    url('fonts/icomoon.woff?wj6ec7') format('woff'),
    url('fonts/icomoon.svg?wj6ec7#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-assign-vehicle:before {
  content: "\e900";
}
.icon-assign-vehicle-new:before {
  content: "\e901";
}
.icon-assign-vehicle-start:before {
  content: "\e902";
}
.icon-client-deliver-request:before {
  content: "\e903";
}
.icon-client-delivery:before {
  content: "\e904";
}
.icon-component-data:before {
  content: "\e905";
}
.icon-end-bit:before {
  content: "\e906";
}
.icon-finished-goods:before {
  content: "\e907";
}
.icon-home:before {
  content: "\e908";
}
.icon-in-between:before {
  content: "\e909";
}
.icon-inventory:before {
  content: "\e90a";
}
.icon-logistics:before {
  content: "\e90b";
}
.icon-mobile-home:before {
  content: "\e90c";
}
.icon-mobile-inventory:before {
  content: "\e90d";
}
.icon-mobile-logistics:before {
  content: "\e90e";
}
.icon-mobile-setting:before {
  content: "\e90f";
}
.icon-mobile-vendor:before {
  content: "\e910";
}
.icon-new-pickup-request:before {
  content: "\e911";
}
.icon-new-receipt:before {
  content: "\e912";
}
.icon-pickup-request:before {
  content: "\e913";
}
.icon-process:before {
  content: "\e914";
}
.icon-purchase-order:before {
  content: "\e915";
}
.icon-raw-matrial:before {
  content: "\e916";
}
.icon-rejections:before {
  content: "\e917";
}
.icon-rm-receipt:before {
  content: "\e918";
}
.icon-scrap-data:before {
  content: "\e919";
}
.icon-setting:before {
  content: "\e91a";
}
.icon-special-trip:before {
  content: "\e91b";
}
.icon-special-trip-request:before {
  content: "\e91c";
}
.icon-special-trip-request-1:before {
  content: "\e91d";
}
.icon-special-trip-request-2:before {
  content: "\e91e";
}
.icon-stock-transfer:before {
  content: "\e91f";
}
.icon-transporter-data:before {
  content: "\e920";
}
.icon-trip-sheet:before {
  content: "\e921";
}
.icon-vendor-data:before {
  content: "\e922";
}
.icon-vendor-invoice:before {
  content: "\e923";
}
.icon-vendors:before {
  content: "\e924";
}
.icon-view-all-receipt:before {
  content: "\e925";
}
.ant-form-item
{
  margin-bottom: 0 !important;
}

.logo {
    height: 32px;
   
    margin: 16px;
    color: #fff;
    text-align: center;
  }
  
  .site-layout .site-layout-background {
    background: #fff;
  }